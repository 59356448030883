import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import {CgPhone} from "react-icons/cg";
import BaseLayout from "../components/global/BaseLayout";

function Services() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Price">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Precios"
          Subheader="Precios"
          bgimg={`${rpdata?.gallery?.[59]}`}
        />

        <div className="w-4/5 mx-auto py-20">
          <h2 className="text-center pb-10">{rpdata?.dbSlogan?.[1].slogan}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 ">
            {rpdata?.works?.map((prod, index) => {
                return (
                  <div
                    className="relative rounded-lg px-3 py-5 hover:transform hover:rotate-1 hover:scale-105 hover:shadow-xl transition-all duration-500"
                    key={index}
                  >
                    <img
                      src={prod?.gallery?.[0]}
                      alt="producto"
                      className="w-full h-[250px] object-contain"
                    />
                    <h4 className="text-[20px] py-2">{prod.name}</h4>
                    <p className="font-bold">
                      Precio: 
                       <span className="font-light"> {' '+ prod.description}</span>
                    </p>

                    <a
                      className="flex items-center bg-red-400 rounded-md hover:transform hover:-rotate-2 transition-all duration-500"
                      href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                    >
                     <article className=" flex justify-center items-center w-full">
                       <CgPhone />
                      <p className="px-2">Agende ahora</p>
                     </article>

                    </a>
                  </div>
                );

            })}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Services;
